import { WITHDRAW_REQUEST_STATUSES } from '@/helpers/withdrawRequestHelpers';
import { ERole } from '@/plugins/role-permissions/types';

export { ERole };
export interface UserLoginRequestData {
  email: string;
  password: string;
}

export interface OperatorWallet {
  walletType: string;
  balance: number;
  currency: string;
  hashId: string;
}

export interface ChangeBlockchainSwapActivePayload {
  walletHash: string;
  active: boolean;
}

export interface BlockchainWalletActivation {
  active: boolean;
}

export interface BlockchainWalletItem {
  active: boolean;
  bnbAmount: number;
  buybackCoefficient: number;
  buybackType: string;
  deliveryCount: number;
  executionTime: string;
  id: number;
  maximumThreshold: number;
  publicKey: string;
  tfsAmount: string;
  type: BlockchainWalletType;
  usdtAmount: number;
  walletError: boolean;
}

export enum BlockchainWalletType {
  buyback = 'BUYBACK',
  liquidity = 'LIQUIDITY',
  trading = 'TRADING',
  autoSellTokenBot = 'AUTOSELL_TOKEN_BOT'
}

export interface BlockchainWallet {
  wallets: BlockchainWalletItem[];
}

export interface BlockchainWalletData {
  privateKey: string;
  publicKey: string;
  type?: BlockchainWalletType;
  id?: number;
}

export enum BlockchainNetworkType {
  'BSC' = 1,
  'ETH' = 2
}

export const BlockchainNetworkProtocol = {
  [BlockchainNetworkType.BSC]: 'BEP-20',
  [BlockchainNetworkType.ETH]: 'ERC-20'
};

export const BlockchainNetworkLabel = {
  [BlockchainNetworkType.BSC]: 'BSC',
  [BlockchainNetworkType.ETH]: 'ETH'
};

export const BlockchainNetworkCurrencyLabel = {
  [BlockchainNetworkType.BSC]: 'BNB',
  [BlockchainNetworkType.ETH]: 'ETH'
};

export const BlockchainNetworkURL = {
  [BlockchainNetworkType.BSC]:
    process.env.VUE_APP_ENVIRONMENT_SLUG === 'PROD'
      ? 'https://bscscan.com'
      : 'https://testnet.bscscan.com',
  [BlockchainNetworkType.ETH]: 'https://etherscan.io'
};

export enum BuybackType {
  linear = 'LINEAR',
  random = 'RANDOM'
}

export interface BuybackSettings {
  buybackCoefficient: number;
  buybackType: BuybackType;
  executionTime: string;
  walletThreshold: number;
  walletTopUpAmount: number;
  buybackVolume: number | null;
}

export interface TradingSettings {
  maximumThreshold: number;
  walletTokenThreshold: number;
  walletTokenTopUpAmount: number;
  walletUSDThreshold: number;
  walletUSDTopUpAmount: number;
  walletBnbTopUpAmount: number | null;
  walletBnbThreshold: number | null;
  configType: string;
}

export interface LiquiditySettings {
  walletTokenThreshold: number;
  walletTokenTopUpAmount: number;
  walletUSDThreshold: number;
  walletUSDTopUpAmount: number;
  walletBnbThreshold: number;
  walletBnbTopUpAmount: number;
}

export interface LiquidityInfo {
  usdt: number;
  token: number;
}

export interface MonthlyBuybacksStats {
  monthlyAmount: number;
  monthlyEmission: number;
}

export interface BlockchainTransaction {
  amountToken: number;
  amountUsd: number;
  date: string;
  fee: number;
  feeBNB: number;
  feeUsd: number;
  hash: string;
  id: number;
  tokenTicker: string;
  trade: string;
  walletHash: string;
}

export interface BlockchainTransactions {
  hasNext: boolean;
  total: number;
  totalAmountUsd: number;
  totalFeeBNB: number;
  totalFeeUsd: number;
  transactions: BlockchainTransaction[];
}

export interface BlockchainTransactionsSummary {
  totalAmountUsd: number;
  totalFeeBNB: number;
  totalFeeUsd: number;
}

export interface CompanyInfo {
  agreementDate: string;
  agreementNumber: string;
  companyAddress: string;
  companyName: string;
  companyNumber: string;
}

export interface TokenPriceData {
  currency: string;
  dailyRatio: number;
  marketCup: number;
  marketCupDailyRatio: number;
  price: number;
  weeklyRatio: number;
  monthlyRatio: number;
  priceInUsd: number;
  rateCurrencyTicker: string;
  usdToUsdtExchangeRate: number;
}

export interface FrozenTokensData {
  amount: number;
  fromLastWeek: number;
  amountUsd: number;
}

export interface TokenInfoData {
  name: string;
  network: string;
  smartContractAddress: string;
  totalSupply: number;
}

export interface FixedPriceTokenEmissionData {
  amount: number;
  amountRatio?: number;
}

export interface ClientsInfoData {
  pancakeSwapUrl: string;
  adminCurrencyTicker: string;
  totalUsers: number;
  totalUsersBalanceToken: number;
  totalUsersBalanceUSD: number;
  totalWithBalanceUsers: number;
  totalUsersWeeklyRatio: number;
  pancakeMaxAmount?: number;
  initialDepositLimitsEnabled: boolean; // dynamic operator deposit limit
  widgetEnabled: boolean;
  enabledOperatorWithdrawal: boolean;
}

export interface UsersItem {
  id: number;
  operatorUserId: string;
  pgUserId: string;
  walletHash: string;
  walletId: number;
}

export interface Users {
  users: UsersItem[];
  hasNext: boolean;
  total: number;
}

export interface UsersRequestParams extends PageData {
  userId?: number;
  walletId?: number;
  operatorUserId?: string;
  walletHashId?: string;
  email?: string;
}

export interface GetStakingProgramsResponse {
  programs: StakingProgram[];
  totalInStakingUsers: number;
  totalUsersLastWeekRatio: number;
}

export interface StakingProgram extends NewStakingProgramData {
  apy: number;
  id: number;
  tokens: number;
  users: number;
  distributedForLastProgramPeriod: number;
}

export enum StakingProgramType {
  GENERAL = 'GENERAL',
  WELCOME = 'WELCOME'
}

export interface NewStakingProgramData {
  durationSeconds?: number;
  status: string;
  minAmount: number;
  name: string;
  rate: number;
  colors: string[];
  limited: boolean;
  type: StakingProgramType;
  maxAmount?: number;
}

export interface UpdateStakingProgramRequestData {
  data: NewStakingProgramData;
  id: number;
}

export interface ProgramDialogData {
  handler: (...args: any[]) => unknown;
  header: string;
  disclaimer?: string;
  buttonText?: string;
}

export interface MiningStatusResponse {
  enabled: boolean;
}

export interface GameType {
  id: number;
  type: string;
  wager: number;
  custom: boolean;
  gamesCount: number;
}

export interface PageData {
  page: number;
  size: number;
  from?: string;
  to?: string;
}

export type DateRangeParams = {
  from: string;
  to: string;
};

export interface VuetifyTableOptions {
  page: number;
  itemsPerPage: number;
  from?: string;
  to?: string;
}

export interface TransactionsPageData extends PageData {
  date: string;
  type: string;
}

export interface GameTypeChangeRequest {
  id: number;
  type?: string;
  wager: number;
}

export interface GameTypeCreateRequest {
  type: string;
  wager: number;
}

export interface GameTypesResponse {
  gameTypes: GameType[];
  hasNext: boolean;
}

export interface GameTypesDropdownItem {
  id: number;
  type: string;
}

export interface GameListItemResponse {
  id: number;
  name: string;
  provider: string;
  gameType: GameTypeItemResponse;
}

export interface GameTypeItemResponse {
  id: number;
  name: string;
}

export interface ProviderStatus {
  id: number;
  name: string;
  miningActive: boolean;
}

export type GetTokenSalePurchasesParams = {
  page: number;
  size: number;
  operatorUserId: string;
};

export type GetTokenSalePurchasesCsvParams = {
  operatorUserId: string;
};

export interface TokenSalePurchase {
  purchaseDate: string;
  operatorUserId: string;
  paymentAmount: number;
  paymentCurrency: string;
  tokenAmount: number;
  bonusTokenAmount: number;
  tokenSaleType: 'PRIVATE' | 'PUBLIC';
}

export interface UserDetails {
  id: number;
  operatorUserId: string;
  email: string;
  walletId: number;
  walletHash: string;
  pgUserId: string;
  blockchainSwapActive: boolean;
  createdAt: string;
  tokenPurchaseCompanyMember: boolean;
  p2eActive: boolean;
  h2eActive: boolean;
}

export interface UserBalancesData {
  walletBalance: number;
  stakedBalance: number;
  limitedProgramCoefficient: number;
  limitedProgramPromoCoefficient: number;
  possibleStakingAmount: number;
  preSaleCoefficient: number | null;
  preSaleVolume: number | null;
  preSaleDepositLimit: number | null;
}

export interface IUserTransactionsSummaryItem {
  amount: number;
  amountUsd: number;
  count: number;
  fee: number;
  feeUsd: number;
  type?: keyof typeof TransactionsTypes;
}

export interface UserTransactionItem {
  attributes: {
    AMOUNT?: number;
    CURRENCY?: string;
    FROM_WALLET_HASH?: string;
    TO_WALLET_HASH?: string;
    AMOUNT_USDT?: string;
  };
  createdAt: string;
  date: string;
  id: string;
  operatorId: string;
  operatorName: string;
  sortId: string;
  type: string;
  userWalletHash: string;
}

export interface OperatorTransactionsListCSVRequestParams {
  from: string;
  to: string;
  type?: TransactionsTypes;
}

export interface UserTransactionsListCSVRequestParams
  extends OperatorTransactionsListCSVRequestParams {
  operatorUserId: string;
}

export interface OperatorTransactionsListRequestParams
  extends OperatorTransactionsListCSVRequestParams {
  size: number;
}

export interface UserTransactionsListRequestParams
  extends UserTransactionsListCSVRequestParams {
  size: number;
}

export interface TransactionItem {
  amount: number;
  fee: number;
  userId: number;
  operatorUserId: string;
  date: string;
  hash: string;
  id: number;
  actionType: TransactionsTypes;
  userWalletBalance: number;
}

export interface TransactionsListResponse extends PagebleListResponse {
  content: TransactionItem[];
  last: boolean;
}

export interface UserTransactionsTotalAmountRequestParams
  extends UserTransactionsListCSVRequestParams {}

export interface UserTransactionsParamsProps {
  operatorUserId?: number;
  type?: string;
  dates?: {
    from: string;
    to: string;
  };
}

export interface TransactionByHashRequestParams {
  hash: string;
}

export interface AuthResponse {
  token: string;
  lastActiveOperator?: number;
}

export interface OperatorUserItem {
  id: number;
  operatorUserId: string;
  operatorId: number;
  role: string;
}
export interface UsersAccountsFilterData {
  role: string;
  operatorId: number;
}

export interface IPItem {
  id: number;
  ipAddress: string;
  filterEnabled: boolean;
}

export interface AccountsOperatorsItem {
  id: number;
  name: string;
  test: boolean;
  status: string;
  envName: string;
  ggr: string;
  isLegalSigned: boolean;
  license: string;
  proxy: string;
  tokenName: string;
  tokenNumber: number;
  tokenPrice: number;
  tokenTicker: string;
  url: string;
  ip: IPItem[];
  liquidityPoolAddress?: string;
}

export interface SelectItem {
  text: string;
  value: number | string | boolean;
  disabled?: boolean;
}

export interface AccountsOperatorsSelectItem extends SelectItem {}

export interface AccountOperatorItem {
  envName: string;
  id: number;
  name: string;
  status: string;
  test: boolean;
  deployed: boolean;
  minted: boolean;
}

export type AccountTokenName = {
  valid: boolean;
};

export interface AccountOperator {
  businessType?: OperatorBusinessType;
  name: string;
  brandName: string;
  id?: number;
  test: boolean;
  status?: string;
  deployed: boolean;
  widgetEnabled: boolean;
}

export interface IAccountListItem {
  name: string;
  children: AccountOperator[] | unknown;
}

export interface CreateAccountOperatorParams {
  operatorName: string;
  tokenName: string;
  businessType: OperatorBusinessType;
  tokenSymbol: string;
  ggr: string;
  activeUsers: string;
  tokenNumber: number;
  tokenPrice: number;
  website: string;
}

export enum EAccountNotification {
  WELCOME = 'WELCOME',
  INVITE_FINANCE = 'INVITE_FINANCE',
  INVITE_MARKETER = 'INVITE_MARKETER',
  INVITE_DEVELOPER = 'INVITE_FINANCE',
  SUCCESS_TRAFFIC = 'SUCCESS_TRAFFIC',
  SUCCESS_ONBOARDING = 'SUCCESS_ONBOARDING'
}

export type TAccountStorage = {
  notification?: EAccountNotification[];
};

export interface AccountItem {
  createdAt: string;
  brandName: string;
  id: number;
  operatorId: number;
  login: string;
  emailVerified: boolean;
  copyStakeAccount: boolean;
  businessType: OperatorBusinessType;
  status: TSuperAdminAccountsStatus;
  operators: AccountOperatorItem[];
}

export interface AccountsParams {
  page: number;
  size: number;
  'sort-direction': string;
  'sort-property': string;
  query?: string;
}

export enum ESuperAdminAccountsStatus {
  NEW = 'NEW',
  INTEGRATION = 'INTEGRATION',
  INTEGRATION_STAGE = 'INTEGRATION_STAGE',
  PROD = 'PROD'
}

export type TSuperAdminAccountsStatus =
  | ESuperAdminAccountsStatus.NEW
  | ESuperAdminAccountsStatus.INTEGRATION
  | ESuperAdminAccountsStatus.INTEGRATION_STAGE
  | ESuperAdminAccountsStatus.PROD;

export interface LoadingStatusPayload {
  status: boolean;
  key?: string;
}

export interface ToggleIPPayload {
  id: number;
  idIp: number;
}

export interface AuthTokenData {
  role: string;
  id_user: number;
  id_operator?: number;
  id_account?: number;
  operators?: number[];
  exp: number;
}

export interface IAuthInviteTokenData {
  role: ERole;
  email: string;
  operatorId: string;
  email_confirmation?: boolean;
}

export interface IAuthGameProviderInviteTokenData {
  create_provider_request: string;
}

export enum OperatorBusinessType {
  mobile = 'MOBILE_APP',
  casino = 'CASINO'
}

export interface OperatorOnboardingForm {
  id?: number;
  ggr: string;
  name: string;
  test: boolean;
  tokenName: string;
  tokenNumber: number;
  tokenPrice: number;
  tokenTicker: string;
  url: string;
}

export interface OperatorOnboardingData extends OperatorOnboardingForm {
  businessType: OperatorBusinessType;
  deployed: boolean;
  ip: IPItem[];
  proxy: string;
  status: string;
  liquidityPoolAddress: string;
}

export interface OperatorOnboardingResponseData {
  businessType: string;
  deployed: boolean;
  id: number;
  name: string;
  status: string;
  test: boolean;
  widgetEnabled: boolean;
}

export interface GameProviderData {
  connected: boolean;
  ipAddresses: string[] | null;
  name: string;
  url: string;
}

export interface OperatorApiKeysData {
  apiKey: string;
  proxyUrl: string;
  acceptProxyUrl: string;
  secret: string;
}

export interface OperatorIPAddress {
  id: number;
  ipAddress: string;
}

export interface OperatorWalletForDeposit {
  address: string;
}

export interface OperatorIPProcessResponse {
  id: number;
}

export interface OperatorMode {
  proxyMode: 'ACCEPT' | 'PROXY' | null;
  url: null | string;
}

export interface NewAccountOperator {
  id?: number;
  name: string;
  test: boolean;
}
export enum Period {
  DAY = 'day',
  MONTH = 'month'
}

export interface GGRItem {
  amount: number;
  amountRatio: number;
}

export interface AccountBalance {
  accountBalance: number;
  accountBalanceLastWeekRatio: number;
  walletBalances: number;
  walletBalancesLastWeekRatio: number;
  inStake: number;
  inStakeLastWeekRatio: number;
}

export interface OperatorItem {
  balance: number;
  currency: string;
  hashId: string;
  walletType: string;
}

export interface SettingsRoleDialogData {
  handler: (...args: any[]) => unknown;
  header: string;
  buttonText?: string;
}

export interface AccountUser {
  id: number;
  operators: number[];
  operatorUserId: string;
  role: ERole;
  accountId?: number;
}

export interface OperatorLanguages {
  defaultLanguage: string;
  languages: string[];
  operatorId: number;
}

export interface DeleteRequestData {
  userId: number;
  accountId?: number;
}

export interface ConfirmationDialogData {
  handler?: (...args: any[]) => unknown;
  header: string;
  disclaimer?: string;
  okText?: string;
  cancelText?: string;
  icon?: string;
  maxWidth?: number;
  isPrimary?: boolean;
}

export interface MediaItemDialogData extends ConfirmationDialogData {
  itemData: IWidgetSocialMedia;
}

export interface OperatorUserCreatePayload {
  role: string;
  operatorUserId: string;
  password: string;
  operators: number[];
}

export interface AccountUserUpdatePayload {
  userId: number;
  role: string;
  operators: number[];
  accountId?: number;
}

export interface WidgetUrlResponce {
  url: string;
}

export interface UserTransactionLimit {
  withdrawLimit: number;
  depositLimit: number;
  cryptoWithdrawLimit: number;
  currentCryptoWithdrawLimit: number;
  currentDepositLimit: number;
  currentWithdrawLimit: number;
  minCryptoWithdrawLimit: number;
  minDepositLimit: number;
  minWithdrawLimit: number;
}

export enum PaymentMethodTypeEnum {
  OPERATOR_DEPOSIT = 'OPERATOR_DEPOSIT',
  OPERATOR_WITHDRAW = 'OPERATOR_WITHDRAW',
  CRYPTO_DEPOSIT_BSC = 'CRYPTO_DEPOSIT_BSC',
  CRYPTO_WITHDRAW_BSC = 'CRYPTO_WITHDRAW_BSC',
  CRYPTO_DEPOSIT_ETH = 'CRYPTO_DEPOSIT_ETH',
  CRYPTO_WITHDRAW_ETH = 'CRYPTO_WITHDRAW_ETH',
  TOKEN_PURCHASE_DEPOSIT = 'TOKEN_PURCHASE_DEPOSIT'
}

export interface CurrentUserTransactionLimit {
  maximumTransactionAmount: number;
  minimumTransactionAmount: number;
  type: PaymentMethodTypeEnum;
}

export interface CurrentUserTransactionLimitResponse
  extends CurrentUserTransactionLimit {
  active: boolean;
  id: number;
  operatorId: number;
  userId: number;
}

export interface PaymentMethod {
  id: number;
  minimumTransactionAmount: number;
  maximumTransactionAmount: number;
  active: boolean;
  type: PaymentMethodTypeEnum;
  fee: number | null;
}

export interface SuperAdminGameProvider {
  name: string;
  enabled: boolean;
}

export enum SuperAdminGameProviderAccountStateType {
  VERIFICATION = 'VERIFICATION'
}

export interface SuperAdminGameProviderAccount {
  id: number;
  apiKey: string;
  email: string;
  gameProviderName: string;
  createdAt: string;
  updatedAt: string;
  companyAddress?: string;
  companyName?: string;
  state: SuperAdminGameProviderAccountStateType;
}

export interface SuperAdminGameProviderOperator {
  id: 0;
  name: string;
  brandName: string;
  businessType: string;
  envName: string;
  status: string;
  test: boolean;
  widgetEnabled: boolean;
}

export interface SuperAdminGameProviderData {
  name: string;
  operators: SuperAdminGameProviderOperator[];
}

export interface NFTItem {
  id: number;
  file?: File;
  price: number;
  benefit: string;
  increaseValue: number;
  durationSeconds: number;
  type: string;
  nftContentType?: string;
  nftAsset?: string;
}

export interface NFTCreatePayload {
  file: File;
  price?: number;
  benefit?: string;
  increaseValue?: number;
  durationSeconds?: number;
  type: string;
}

export interface NFTUpdatePayload {
  id: number;
  price: number;
  benefit: string;
  increaseValue: number;
  durationSeconds: number;
}

export interface NFTDialogData {
  handler: (...args: any[]) => unknown;
  header: string;
  operation: string;
  item: NFTItem;
}

export interface SideMenuSubLink {
  title: string;
  to: string;
}

export interface SideMenuLink {
  to?: string;
  icon: string;
  title: string;
  roles?: string[];
  visible?: boolean;
  children?: SideMenuSubLink[];
}

export interface SuperAdminMenuLink {
  title: string;
  icon: string;
  routeName: string;
  children?: {
    title: string;
    routeName: string;
  }[];
  visible?: boolean;
}

export interface PagebleListResponse {
  empty: boolean;
  numberOfElements: number;
  totalElements: number;
  number: number;
  size: number;
}

export interface PagebleListSearchParams {
  page: number | null;
  size: number | null;
  operatorUserId?: string;
}

export interface PromoCampainPartisipanstSearchParams
  extends PagebleListSearchParams {
  direction?: 'ASC' | 'DESC';
  from?: string;
  to?: string;
}

export interface PromoCampaignData {
  betVolumeRule: number;
  cashbackTime: number;
  dailyCashbackReward: number;
  depositReward: number;
  dayOfWeek: string;
  duration: number;
  expirationDate: string;
  fixedAmountReward: number;
  kycRule: boolean;
  maxUserCount: number;
  name: string;
  p2eMultiplierReward: number;
  signUpRule: boolean;
  stakingLimitCoefficientReward: number;
  weeklyCashbackReward: number;
  weeklyCashbackTime: number;
  preSaleCoefficient: number | null;
  createdAt?: string;
  id?: number;
  participants?: PromoCampaignParticipant[] | null;
  status?: string;
  pushSubscriptionRule?: boolean;
  sumFixedAmountReward?: number;
  allUsers?: boolean;
  dailyCashbackRewardAmount?: number;
  registeredAfter?: string;
  accrualCondition?: EPromoAccrualCondition;
  conditionMin?: number;
  conditionMax?: number;
  percentAmountReward?: number;
  dailyLimit?: number;
  depositLimit?: number;
  weeklyLimit?: number;
  cashbackGameTypes: ECashbackGameTypes[];
}

export enum ECashbackGameTypes {
  CASINO = 'CASINO',
  SPORTS_BOOK = 'SPORTS_BOOK'
}

export interface IPromoCampaignFormData {
  campaignType: string;
  campaignName: string;
  fixedReward: string;
  extraP2eReward: string;
  extraH2eReward: string;
  percentageReward: number;
  dailyCashbackReward: number;
  dailyCashbackTime: number;
  dailyCashbackGameType: ECashbackGameTypes[];
  weeklyCashbackReward: number;
  weeklyCashbackDay: string;
  weeklyCashbackTime: number;
  weeklyCashbackGameType: ECashbackGameTypes[];
  deadlineDate: string;
  durationTime: string;
  maxUsersCount: string;
  greaterAccrualCondition: string;
  lessAccrualCondition: string;
  intervalFromAccrualCondition: string;
  intervalToAccrualCondition: string;
  dailyLimitReward: string;
  weeklyLimitReward: string;
  fixedAccrualLimitReward: string;
  percentageAccrualLimitReward: string;
  preSaleCoefficient: string;
  audience: string;
  allUsers: boolean;
  registeredAfter: string;
  startDate: string;
}

export interface IPromoCampaignEnabledFields {
  fixedTokensReward: boolean;
  extraP2eReward: boolean;
  extraH2eReward: boolean;
  maxUsersCount: boolean;
  cashbackType: string;
  accrualFormatType: string;
  accrualConditionType: string;
  customPeriodType: string;
  audience: string;
  dailyCashbackGameType: ECashbackGameTypes[];
  weeklyCashbackGameType: ECashbackGameTypes[];
}

export interface PromoCampaignParticipant {
  campingId: number;
  createdAt: string;
  transaction: string;
  userId: string;
}
export interface PromoCampaignListResponse extends PagebleListResponse {
  content: PromoCampaignData[];
}

export interface PromoCampaignByUser {
  id: number;
  name: string;
  status: string;
}

export enum PromoCampaignStatus {
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
  DRAFT = 'DRAFT',
  EXPIRED = 'EXPIRED'
}

export enum OperatorWalletType {
  MASTER = 'MASTER',
  REWARD = 'REWARD',
  TOKEN_SALE = 'TOKEN_SALE'
}

export interface PromoCampaignUpdateData {
  status: PromoCampaignStatus;
  id: number;
}

export interface DialogCampaignData {
  key?: string;
  status: boolean;
  data?: PromoCampaignData;
}

export interface PromoParticipantData {
  operatorUserId: string;
  campingId: number;
  transaction: string;
  createdAt: string;
  method: 'API' | 'MANUAL' | 'AUTOMATIC';
}
export interface PromoParticipantsListResponse extends PagebleListResponse {
  content: PromoParticipantData[];
}

export interface PromoParticipantCashbackUserResponse {
  quantity: number;
  rewardUsd: number;
  reward: number;
  operatorUserId: number;
  currency: string;
}
export interface PromoParticipantCashbackResponse extends PagebleListResponse {
  content: PromoParticipantCashbackUserResponse[];
}

export interface PromoParticipantTotalParams {
  from: string;
  to: string;
}

export interface PromoParticipantCashbackTotalResponse {
  rewardUsd: number;
  reward: number;
  currency: string;
}

export interface TransferPayload {
  amount: number;
  from: OperatorWalletType;
  to: OperatorWalletType;
}

export interface WithdrawWalletPayload {
  amount: number;
  address: string;
  from: OperatorWalletType;
}

export interface WithdrawWalletResponce {
  amount: number;
  currency: string;
  state: string;
}

export interface ArrayItem {
  text: string;
  value: string;
  disabled?: boolean;
}

export enum EFeatureFlagType {
  PRODUCT = 'PRODUCT',
  TECH = 'TECH'
}

export enum EFeatureFlagListType {
  ALL_USERS = 'ALL_USERS',
  WHITE_LIST = 'WHITE_LIST',
  BLACK_LIST = 'BLACK_LIST',
  AFFILIATE_ALL_USERS = 'AFFILIATE_ALL_USERS',
  AFFILIATE_WHITE_LIST = 'AFFILIATE_WHITE_LIST',
  AFFILIATE_BLACK_LIST = 'AFFILIATE_BLACK_LIST'
}

export interface FeatureFlagItem {
  featureFlagId: number;
  name: string;
  enabled: boolean;
  adminVisible: boolean;
  operatorUserIds: string[];
  partnerIds: string[];
  listType: EFeatureFlagListType;
  count: number;
  random: boolean;
  percentage: number;
  type: EFeatureFlagType;
}

export interface FeatureFlagABTestParams {
  enable: boolean;
  percentage?: number;
}

export interface ManageIdsFeatureFlagPayload {
  listType: EFeatureFlagListType;
  operatorUserIds?: string[];
  partnerIds?: number[];
}

export interface GetWithdrawRequestsPayload {
  state: WITHDRAW_REQUEST_STATUSES;
  operatorUserId: string;
  id: number;
  page: number;
  size: number;
  from: string;
  to: string;
}
export interface WithdrawRequestCommentItem {
  comment: string;
  createdAt: string;
  declinedReason: boolean;
  id: number;
  operatorUserId: string;
}

export interface WithdrawRequestHistoryItem {
  createdAt: string;
  id: number;
  operatorUserId: string;
  newStatus: string;
  previousStatus: string;
  system: boolean;
}

export interface GetWithdrawRequestsResponse {
  content: WithdrawRequestItem[];
  totalElements: number;
  totalPages: number;
}

export interface WithdrawRequestItem {
  comments: WithdrawRequestCommentItem[];
  history: WithdrawRequestHistoryItem[];
  amount: number;
  createdAt: string;
  declinedReason: string;
  id: number;
  operatorUserId: string;
  walletToWithdraw: string;
  status: string;
}

export interface GetWithdrawRequestByIdPayload {
  id: number;
}

export interface ApproveWithdrawRequestPayload {
  id: number;
  comment?: string;
  declinedReason?: string;
  status: string;
}

export interface ApproveWithdrawLiquidityRequestPayload {
  hash: string;
  lpAmount: number;
  tfsAmount: number;
  usdtAmount: number;
  withdrawId: number;
}

export interface CommentWithdrawRequestPayload {
  id: number;
  comment: string;
}

export interface EnableWidgetPayload {
  operatorId: number;
  enabled: boolean;
}

export interface OperatorBalanceOverallData {
  beginningBalance: number;
  beginningBalanceUsd: number;
  endingBalance: number;
  endingBalanceUsd: number;
  totalExpenses: number;
  totalExpensesUsd: number;
  beginningTokenPrice: number;
  endingTokenPrice: number;
  totalFee: number;
  totalTokenFee: number;
  totalTokenReleased: number;
  totalTokenReleasedUsdt: number;
  fees: OperatorBalanceOverallFeesItem[];
}

export interface OperatorBalanceOverallFeesItem {
  amount: number;
  tokenAmount: number;
  fee: number;
  tokenFee: number;
  type: TransactionsTypes;
}

export interface OperatorBalance {
  casinoRewardFeeActive: boolean;
  casinoRewardFee: number;
  casinoSharesMultiplier: number;
  casinoSharesMultiplierActive: boolean;
  currentBalance: number;
  dailyCryptoDepositFee: number;
  dailyCryptoWithdrawFee: number;
  dailyDepositFee: number;
  dailyH2eFee: number;
  dailyP2eFee: number;
  dailyWithdrawFee: number;
  h2eActive: boolean;
  h2eFee: number;
  loyaltySwapFeeActive: boolean;
  loyaltySwapFee: number;
  minimumBalanceThreshold?: number;
  monthlyCryptoDepositFee: number;
  monthlyCryptoWithdrawFee: number;
  monthlyDepositFee: number;
  monthlyH2eFee: number;
  monthlyP2eFee: number;
  monthlyWithdrawFee: number;
  p2eActive: boolean;
  p2eFee: number;
  thresholdNotificationLevel?: number;
}

export enum EOperatorInvoiceStatus {
  PENDING = 'PENDING',
  PAID = 'PAID',
  NOT_PAID = 'NOT_PAID',
  CANCELLED = 'CANCELLED'
}

export type TOperatorInvoiceStatus =
  | EOperatorInvoiceStatus.PENDING
  | EOperatorInvoiceStatus.PAID
  | EOperatorInvoiceStatus.NOT_PAID
  | EOperatorInvoiceStatus.CANCELLED;

export enum EOperatorInvoiceType {
  INVOICE = 'INVOICE',
  PLEDGED_AMOUNT = 'PLEDGED_AMOUNT',
  FEE = 'FEE'
}

export type TOperatorInvoiceType =
  | EOperatorInvoiceType.INVOICE
  | EOperatorInvoiceType.PLEDGED_AMOUNT
  | EOperatorInvoiceType.FEE;

export const OperatorInvoiceTypeNaming: Record<TOperatorInvoiceType, string> = {
  [EOperatorInvoiceType.INVOICE]: 'Invoice',
  [EOperatorInvoiceType.PLEDGED_AMOUNT]: 'Pledged Amount',
  [EOperatorInvoiceType.FEE]: 'Set-up Fee'
};

export interface OperatorInvoiceHistoryItem {
  id: number;
  date: string;
  brandName: string;
  operatorId: number;
  month: string;
  type: TOperatorInvoiceType;
  status: TOperatorInvoiceStatus;
  amount: number;
  description: string;
  number: string;
}

export interface SuperAdminOperatorInvoicePayload extends PageData {
  status?: TOperatorInvoiceStatus;
  brandName?: string;
}

export interface Invoice {
  filename: string;
  uri: string;
}

export interface OperatorInvoiceHistory extends PagebleListResponse {
  content: OperatorInvoiceHistoryItem[];
}

export enum TransactionsTypes {
  // Withdraw
  WITHDRAW = 'WITHDRAW',
  CRYPTO_WITHDRAW = 'CRYPTO_WITHDRAW',
  LIQUIDITY_WITHDRAW = 'LIQUIDITY_WITHDRAW',
  LIQUIDITY_DEPOSIT_ROLLBACK = 'LIQUIDITY_DEPOSIT_ROLLBACK',

  // Deposit
  DEPOSIT = 'DEPOSIT',
  CRYPTO_DEPOSIT = 'CRYPTO_DEPOSIT',
  LIQUIDITY_DEPOSIT = 'LIQUIDITY_DEPOSIT',

  // Promo
  PROMO_CAMPAIGN_REWARD = 'PROMO_CAMPAIGN_REWARD',
  DAILY_CASHBACK = 'DAILY_CASHBACK',
  WEEKLY_CASHBACK = 'WEEKLY_CASHBACK',
  PROMO_DEPOSIT_REWARD = 'PROMO_DEPOSIT_REWARD',
  CASINO_REWARD = 'CASINO_REWARD',

  // Play To Earn
  BETTING_REWARD = 'BETTING_REWARD',
  BETTING_REWARD_COPIED = 'BETTING_REWARD_COPIED',

  //Hold To Earn
  STAKING_REWARD = 'STAKING_REWARD',

  // Hold
  STAKE = 'STAKE',

  // Unhold
  UNSTAKE = 'UNSTAKE',

  // Rollback
  BETTING_ROLLBACK = 'BETTING_ROLLBACK',
  BETTING_ROLLBACK_COPIED = 'BETTING_ROLLBACK_COPIED',

  // Transfer
  TRANSFER = 'TRANSFER',
  TRANSFER_IN = 'TRANSFER_IN',
  TRANSFER_OUT = 'TRANSFER_OUT',

  // Referral Reward
  REFERRAL_H2E_REWARD = 'REFERRAL_H2E_REWARD',
  REFERRAL_P2E_REWARD = 'REFERRAL_P2E_REWARD',
  REFERRAL_REGISTRATION_REWARD = 'REFERRAL_REGISTRATION_REWARD',

  // Token Sale
  TOKEN_SALE_PURCHASE = 'TOKEN_SALE_PURCHASE',

  // Other
  MINT = 'MINT',
  BURN = 'BURN',
  DISTRIBUTION = 'DISTRIBUTION',
  LOYALTY_SWAP = 'LOYALTY_SWAP'
}

export const TransactionsTypeNaming = {
  //Withdraw
  [TransactionsTypes.WITHDRAW]: 'Withdraw',
  [TransactionsTypes.CRYPTO_WITHDRAW]: 'Crypto Withdraw',
  [TransactionsTypes.LIQUIDITY_WITHDRAW]: 'Liquidity Withdraw',
  [TransactionsTypes.LIQUIDITY_DEPOSIT_ROLLBACK]: 'Liquidity Deposit Rollback',

  //Deposit
  [TransactionsTypes.DEPOSIT]: 'Deposit',
  [TransactionsTypes.CRYPTO_DEPOSIT]: 'Crypto Deposit',
  [TransactionsTypes.LIQUIDITY_DEPOSIT]: 'Liquidity Deposit',

  //Promo
  [TransactionsTypes.PROMO_CAMPAIGN_REWARD]: 'Promo Reward',
  [TransactionsTypes.DAILY_CASHBACK]: 'Daily Cashback',
  [TransactionsTypes.WEEKLY_CASHBACK]: 'Weekly Cashback',
  [TransactionsTypes.PROMO_DEPOSIT_REWARD]: 'Deposit Volume Reward',
  [TransactionsTypes.CASINO_REWARD]: 'Casino Reward',

  //Play To Earn
  [TransactionsTypes.BETTING_REWARD]: 'Play to Earn',
  [TransactionsTypes.BETTING_REWARD_COPIED]: 'Play to Earn (CopyStake)',

  //Hold To Earn
  [TransactionsTypes.STAKING_REWARD]: 'Hold to Earn',

  //Hold
  [TransactionsTypes.STAKE]: 'Hold',

  //Unhold
  [TransactionsTypes.UNSTAKE]: 'Unhold',

  //Rollback
  [TransactionsTypes.BETTING_ROLLBACK]: 'Play to Earn Rollback',
  [TransactionsTypes.BETTING_ROLLBACK_COPIED]:
    'Play to Earn Rollback (CopyStake)',

  //Transfer
  [TransactionsTypes.TRANSFER]: 'Transfer',
  [TransactionsTypes.TRANSFER_IN]: 'Transfer In',
  [TransactionsTypes.TRANSFER_OUT]: 'Transfer Out',

  //Referral Reward
  [TransactionsTypes.REFERRAL_P2E_REWARD]: 'Referral P2E Reward',
  [TransactionsTypes.REFERRAL_H2E_REWARD]: 'Referral H2E Reward',
  [TransactionsTypes.REFERRAL_REGISTRATION_REWARD]:
    'Referral Registration Reward',

  //Token Sale
  [TransactionsTypes.TOKEN_SALE_PURCHASE]: 'Token Sale Purchase',

  //Other
  [TransactionsTypes.MINT]: 'Mint',
  [TransactionsTypes.BURN]: 'Burn',
  [TransactionsTypes.DISTRIBUTION]: 'Distribution',
  [TransactionsTypes.LOYALTY_SWAP]: 'Loyalty Swap'
};

export const transactionTypeMapConfig = {
  DEPOSIT: { label: 'Crypto Deposit', value: 'DEPOSIT' },
  TRANSFER: { label: 'Crypto Transfer', value: 'TRANSFER' },
  WITHDRAWAL: { label: 'Crypto Withdrawal', value: 'WITHDRAWAL' },
  MERCHANT_DEPOSIT: { label: 'Merchant Deposit', value: 'MERCHANT_DEPOSIT' },
  MERCHANT_TRANSFER: {
    label: 'Merchant Withdrawal',
    value: 'MERCHANT_TRANSFER'
  }
};

export enum ETransactionTypeWithNetwork {
  DEPOSIT_BSC = 'DEPOSIT_BSC',
  DEPOSIT_ERC = 'DEPOSIT_ERC',
  WITHDRAWAL_BSC = 'WITHDRAWAL_BSC',
  WITHDRAWAL_ERC = 'WITHDRAWAL_ERC',
  TRANSFER_BSC = 'TRANSFER_BSC',
  TRANSFER_ERC = 'TRANSFER_ERC',
  TRANSFER = 'TRANSFER',
  MERCHANT_DEPOSIT_BSC = 'MERCHANT_DEPOSIT_BSC',
  MERCHANT_DEPOSIT_ERC = 'MERCHANT_DEPOSIT_ERC',
  MERCHANT_DEPOSIT = 'MERCHANT_DEPOSIT',
  MERCHANT_TRANSFER_BSC = 'MERCHANT_TRANSFER_BSC',
  MERCHANT_TRANSFER_ERC = 'MERCHANT_TRANSFER_ERC',
  MERCHANT_TRANSFER = 'MERCHANT_TRANSFER'
}

export type TTransactionTypeWithNetwork =
  | ETransactionTypeWithNetwork.DEPOSIT_BSC
  | ETransactionTypeWithNetwork.DEPOSIT_ERC
  | ETransactionTypeWithNetwork.WITHDRAWAL_BSC
  | ETransactionTypeWithNetwork.WITHDRAWAL_ERC
  | ETransactionTypeWithNetwork.TRANSFER_BSC
  | ETransactionTypeWithNetwork.TRANSFER_ERC
  | ETransactionTypeWithNetwork.TRANSFER
  | ETransactionTypeWithNetwork.MERCHANT_DEPOSIT_BSC
  | ETransactionTypeWithNetwork.MERCHANT_DEPOSIT_ERC
  | ETransactionTypeWithNetwork.MERCHANT_DEPOSIT
  | ETransactionTypeWithNetwork.MERCHANT_TRANSFER_BSC
  | ETransactionTypeWithNetwork.MERCHANT_TRANSFER_ERC
  | ETransactionTypeWithNetwork.MERCHANT_TRANSFER;

export const TransactionTypeWithNetworkNaming: Record<
  TTransactionTypeWithNetwork,
  string
> = {
  [ETransactionTypeWithNetwork.DEPOSIT_BSC]: 'Crypto Deposit BSC',
  [ETransactionTypeWithNetwork.DEPOSIT_ERC]: 'Crypto Deposit ERC',
  [ETransactionTypeWithNetwork.WITHDRAWAL_BSC]: 'Crypto Withdrawal BSC',
  [ETransactionTypeWithNetwork.WITHDRAWAL_ERC]: 'Crypto Withdrawal ERC',
  [ETransactionTypeWithNetwork.TRANSFER_BSC]: 'Crypto Transfer BSC',
  [ETransactionTypeWithNetwork.TRANSFER_ERC]: 'Crypto Transfer ERC',
  [ETransactionTypeWithNetwork.TRANSFER]: 'Crypto Transfer',
  [ETransactionTypeWithNetwork.MERCHANT_DEPOSIT_BSC]: 'Merchant Deposit BSC',
  [ETransactionTypeWithNetwork.MERCHANT_DEPOSIT_ERC]: 'Merchant Deposit ERC',
  [ETransactionTypeWithNetwork.MERCHANT_DEPOSIT]: 'Merchant Deposit',
  [ETransactionTypeWithNetwork.MERCHANT_TRANSFER_BSC]:
    'Merchant Withdrawal BSC',
  [ETransactionTypeWithNetwork.MERCHANT_TRANSFER_ERC]:
    'Merchant Withdrawal ERC',
  [ETransactionTypeWithNetwork.MERCHANT_TRANSFER]: 'Merchant Withdrawal'
};

export const FiltersTransactionTypeWithNetworkMapConfig = [
  {
    label: TransactionTypeWithNetworkNaming.DEPOSIT_BSC,
    value: ETransactionTypeWithNetwork.DEPOSIT_BSC
  },
  {
    label: TransactionTypeWithNetworkNaming.WITHDRAWAL_BSC,
    value: ETransactionTypeWithNetwork.WITHDRAWAL_BSC
  },
  {
    label: TransactionTypeWithNetworkNaming.DEPOSIT_ERC,
    value: ETransactionTypeWithNetwork.DEPOSIT_ERC
  },
  {
    label: TransactionTypeWithNetworkNaming.WITHDRAWAL_ERC,
    value: ETransactionTypeWithNetwork.WITHDRAWAL_ERC
  },
  {
    label: TransactionTypeWithNetworkNaming.TRANSFER,
    value: ETransactionTypeWithNetwork.TRANSFER
  },
  {
    label: TransactionTypeWithNetworkNaming.MERCHANT_DEPOSIT,
    value: ETransactionTypeWithNetwork.MERCHANT_DEPOSIT
  },
  {
    label: TransactionTypeWithNetworkNaming.MERCHANT_TRANSFER,
    value: ETransactionTypeWithNetwork.MERCHANT_TRANSFER
  }
];

export enum MobileEventStatus {
  active = 'ACTIVE',
  deactivated = 'DEACTIVATED'
}

export interface NewMobileEvent {
  name: string;
  reward: number;
  status: MobileEventStatus;
}

export interface MobileEventItem extends NewMobileEvent {
  count: number;
  id: number;
  totalReward: number;
}

export interface ResponseObject<T> {
  content: T[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: {
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    sort: {
      empty: boolean;
      sorted: boolean;
      unsorted: boolean;
    };
    unpaged: boolean;
  };
  size: number;
  sort: {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
  };
  totalElements: number;
  totalPages: number;
}

export interface UserTransferBalanceData {
  actionId: string;
  amount: number;
  message: string;
  reason: string;
}

export interface UserTransferReason {
  amount: number;
  createdAt: string;
  id: number;
  message: string;
  reason: string;
  txHash: string;
  type: string;
  adminEmail: string;
}

export interface PaymentsLimitUserData {
  active: boolean;
  id: number;
  maximumTransactionAmount: number;
  minimumTransactionAmount: number;
  operatorId: number;
  type: string;
  userId: number;
  operatorUserId?: string;
}

export interface PaymentsLimitUsersResponse extends PagebleListResponse {
  content: PaymentsLimitUserData[];
}

export interface OperatorWalletItem {
  balance: number;
  currency: string;
  hashId: string;
}

export interface WalletTransactionItem {
  amount: number;
  amountUsd: number;
  date: string;
  receiver: string;
  sender: string;
  type: string;
}

export interface WalletTransactionsResponce extends PagebleListResponse {
  content: WalletTransactionItem[];
}

export interface AvgNgrStatsResponseItem {
  ggrStakingUsers: number;
  ggrNotStakingUsers: number;
  p2eStakingUsersUsd: number;
  p2eNotStakingUsersUsd: number;
  h2eStakingUsersUsd: number;
  h2eNotStakingUsersUsd: number;
  promoStakingUsersUsd: number;
  promoNotStakingUsersUsd: number;
  stakeUsersCount: number;
  nonStakeUsersCount: number;
  date: string;
}

// export interface AvgNgrStatsItem extends AvgNgrStatsResponseItem {
//   stakesNgr: number;
//   nonStakesNgr: number;
// }

export interface AvgNgrStatsItem {
  stakeNgr: number;
  nonStakeNgr: number;
  calculationDate: string;
  date?: string;
}

export interface AvgUsersStatsItem {
  stakeUsersCount: number;
  nonStakeUsersCount: number;
  notVisitedWidgetUsersCount: number;
  date: string;
}

export interface ProfitStatisticData {
  nonStakeGgr: null | number;
  nonStakeNgr: null | number;
  nonStakeUserCount: null | number;
  stakeGgr: null | number;
  stakeNgr: null | number;
  stakeUserCount: null | number;
}

export enum EColorsKey {
  MAIN_COLOR = 'main-color',
  THEME_BACKGROUND_COLOR = 'theme-background-color',
  TEXT_COLOR = 'text-color',
  THEME_BLOCK_BACKGROUND_COLOR = 'theme-block-background-color',
  TEXT_COLOR_BUTTON = 'text-color-button',
  THEME_BLOCK_INNER_BACKGROUND_COLOR = 'theme-block-inner-background-color'
}

export interface IWidgetThemeColor {
  type: EColorsKey;
  color: string;
}

export interface IWidgetTheme {
  value: string | null;
  colors: IWidgetThemeColor[];
}

export enum EWidgetSocialMedia {
  TELEGRAM = 'TELEGRAM',
  VIBER = 'VIBER',
  INSTAGRAM = 'INSTAGRAM',
  FACEBOOK = 'FACEBOOK',
  TWITTER = 'TWITTER',
  DISCORD = 'DISCORD'
}

export interface IWidgetSocialMedia {
  id?: number;
  title: string;
  link: string;
  socialMediaType: EWidgetSocialMedia;
}

export type TWidgetSocialMediaList = Record<
  EWidgetSocialMedia,
  IWidgetSocialMedia[]
>;

export interface SelectUsersDialogPayload extends FeatureFlagItem {
  operatorUserIds: string[];
  percentage: number;
  random: boolean;
  name: string;
}

export interface PaymentsLimitPage extends PageData {
  operatorUserId: string;
  type?:
    | PaymentMethodTypeEnum.OPERATOR_WITHDRAW
    | PaymentMethodTypeEnum.OPERATOR_DEPOSIT;
}
export interface IUserInfo {
  createdAt: string;
  emailVerified: boolean;
  id: number;
  login: string;
}

export interface AccountInfo extends IUserInfo {
  brandName: string;
  businessType: string;
}

export interface IInviteUserDialogOptions {
  title?: string;
  description?: string;
  list?: [];
  show: boolean;
  closable?: boolean;
  type?: ERole;
  hideTitle?: boolean;
}

export interface PromoCampaignDataRequest {
  size?: number;
  page?: number;
  status?: string;
  from?: string;
  to?: string;
  sortBy: string;
  direction: string;
}

export interface BuybacksDataRequest {
  size?: number;
  page?: number;
  dateFrom?: string;
  dateTo?: string;
}

export interface BlockchainWalletReportRequest {
  size?: number;
  page?: number;
  date?: string;
}

export interface BuybackData {
  buybackDone: number;
  buybackNeeded: number;
  date: string;
  holdToEarnReward: number;
  playToEarnReward: number;
  transferIn: number;
  transferOut: number;
}

export interface BuybacksListResponse extends PagebleListResponse {
  content: BuybackData[];
}

export interface ITradingDetailsParams {
  size?: number;
  page?: number;
  dateFrom?: string;
  dateTo?: string;
}

export interface ITradingDetailsItem {
  date: string;
  deposit: number;
  withdrawal: number;
  pancakeDeposit: number;
  withdrawalPancake: number;
}

export interface ITradingDetailsResponse extends PagebleListResponse {
  content: ITradingDetailsItem[];
}

export interface FirstBuybackDayResponse {
  date: string;
}

export type WidgetDemoURL = {
  url: string;
};

export interface RewardsInfo {
  stakeUsersCount: number;
  nonStakeUsersCount: number;
  totalUsersCount: number;
  notVisitedWidgetUsersCount: number;
  ggrStakingUsers: number;
  ggrNotStakingUsers: number;
  totalGgr: number;
  ngrStakingUsers: number;
  ngrNotStakingUsers: number;
  totalNgr: number;
  p2eStakingUsers: number;
  p2eStakingUsersUsd: number;
  p2eStakingUsersOperatorCurrency: number;
  totalP2e: number;
  p2eNotStakingUsers: number;
  p2eNotStakingUsersUsd: number;
  p2eNotStakingUsersOperatorCurrency: number;
  totalP2eOperatorCurrency: number;
  h2eStakingUsers: number;
  h2eStakingUsersUsd: number;
  h2eStakingUsersOperatorCurrency: number;
  h2eNotStakingUsers: number;
  h2eNotStakingUsersUsd: number;
  h2eNotStakingUsersOperatorCurrency: number;
  totalH2e: number;
  totalH2eOperatorCurrency: number;
  promoStakingUsers: number;
  promoStakingUsersUsd: number;
  promoStakingUsersOperatorCurrency: number;
  promoNotStakingUsers: number;
  promoNotStakingUsersUsd: number;
  promoNotStakingUsersOperatorCurrency: number;
  totalPromo: number;
  totalPromoOperatorCurrency: number;
  burnStakingUsers: number;
  burnStakingUsersUsd: number;
  burnStakingUsersOperatorCurrency: number;
  burnNotStakingUsers: number;
  burnNotStakingUsersUsd: number;
  burnNotStakingUsersOperatorCurrency: number;
  totalBurn: number;
  totalBurnOperatorCurrency: number;
}

export interface ParamsGraphRequest {
  from: string;
  to?: string;
}

export interface MerchantData {
  id: number;
  name: string;
  token: string;
  tokenId: number;
  address: string;
  walletId: number;
  networkId: number;
  contractAddress: string;
}
export interface OperatosMerchantResponce extends PagebleListResponse {
  content: MerchantData[];
}

export interface OperatorMerchantsRequestParams
  extends PagebleListSearchParams {
  id?: number;
}

export interface CustodyTransactionData {
  id: number;
  name: string;
  date: string;
  token: string;
  amount: number;
  fee: number;
  hash: string;
  type: string;
  from: string;
  to: string;
  networkId: number;
}

export interface CustodyTransactionResponse extends PagebleListResponse {
  content: CustodyTransactionData[];
}

export interface CustodyTransactionRequestParams
  extends PagebleListSearchParams {
  name?: string;
  hash?: string;
  type: string;
  from?: string;
  to?: string;
  walletHash?: string;
  fromDate?: string;
  toDate?: string;
}

export enum EOnbordingStepStatus {
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING'
}

export interface MerchantTokenBalance {
  address: string;
  balance: number;
  tokenId: number | null;
}

export interface MerchantFinanceWalletBalance {
  address: string;
  balance: number;
}

export interface MerchantTokenWithdraw {
  address: string;
  amount: number;
  tokenSymbol: string;
}

export interface MerchantTokenBalanceRequestParams {
  externalMerchantId: number;
  tokenSymbol?: string;
  networkId: number;
}

export interface MerchantTransactionData {
  id: number;
  fromAddress: string;
  toAddress: string;
  fromWalletId: number;
  toWalletId: number;
  hash: string;
  amount: number;
  fee: number;
  gasPrice: number;
  type: string;
  status: string;
  tokenId: number;
  merchantId: number;
  networkId: number;
  createdAt: string;
  updatedAt: string;
}

export interface MerchantTransactionRequestParams
  extends PagebleListSearchParams {
  tokenId: number;
  walletId?: number;
  transactionType?: string;
  addressFrom?: string;
  addressTo?: string;
  fromDate?: string;
  toDate?: string;
}

export interface MerchantTransactionDataResponse extends PagebleListResponse {
  content: MerchantTransactionData[];
}

export interface MerchantUserWallet {
  id: number;
  address: string;
  userId: number;
  createdAt: string;
}

export interface MerchantUserWalletRequestParams
  extends PagebleListSearchParams {
  address?: string;
  userId?: number;
}

export interface MerchantUserWalletResponse extends PagebleListResponse {
  content: MerchantUserWallet[];
}

export enum EOnbordingStep {
  accountCreationProgress = 'accountCreationProgress',
  getUrlProgress = 'getUrlProgress',
  integrationSettingsProgress = 'integrationSettingsProgress',
  inviteDevProgress = 'inviteDevProgress',
  tokenExchangeProgress = 'tokenExchangeProgress',
  tokenInfoProgress = 'tokenInfoProgress',
  trafficProgress = 'trafficProgress',
  uiSettingsProgress = 'uiSettingsProgress',
  userSupportProgress = 'userSupportProgress',
  whitelistProgress = 'whitelistProgress',
  buildCommunityProgress = 'buildCommunityProgress',
  createAutoCommunicationProgress = 'createAutoCommunicationProgress',
  inviteMarketingManagerProgress = 'inviteMarketingManagerProgress',
  launchLoyaltyPageProgress = 'launchLoyaltyPageProgress',
  overallProgress = 'overallProgress',
  gameProviders = 'gameProvidersProgress'
}

export type TOnboardingPayloadStepType =
  | EOnbordingStep.integrationSettingsProgress
  | EOnbordingStep.inviteDevProgress
  | EOnbordingStep.tokenInfoProgress
  | EOnbordingStep.uiSettingsProgress
  | EOnbordingStep.userSupportProgress
  | EOnbordingStep.whitelistProgress
  | EOnbordingStep.buildCommunityProgress
  | EOnbordingStep.createAutoCommunicationProgress
  | EOnbordingStep.inviteMarketingManagerProgress
  | EOnbordingStep.launchLoyaltyPageProgress;

export type TOnboardingStepPayload = Record<
  TOnboardingPayloadStepType,
  EOnbordingStepStatus
>;

export type TOnboardingProgress = Record<EOnbordingStep, EOnbordingStepStatus>;

export interface itemLightBox {
  src: string;
  name?: string;
  width?: number;
  height?: number;
  title?: string;
  description?: string;
}

export enum EOperatorEventsType {
  BALANCE_CHANGE = 'BALANCE_CHANGE',
  CRYPTO_DEPOSIT_SUCCESS = 'CRYPTO_DEPOSIT_SUCCESS',
  CRYPTO_WITHDRAW_SUCCESS = 'CRYPTO_WITHDRAW_SUCCESS',
  HOLD_TO_EARN = 'HOLD_TO_EARN',
  PLAY_TO_EARN = 'PLAY_TO_EARN'
}

export enum EOperationLimitCoefficientType {
  STAKING_LIMIT = 'STAKING_LIMIT',
  TOKEN_PRE_SALE = 'TOKEN_PRE_SALE_COEFFICIENT'
}

export type TOperatorEventsType =
  | EOperatorEventsType.BALANCE_CHANGE
  | EOperatorEventsType.CRYPTO_DEPOSIT_SUCCESS
  | EOperatorEventsType.CRYPTO_WITHDRAW_SUCCESS
  | EOperatorEventsType.HOLD_TO_EARN
  | EOperatorEventsType.PLAY_TO_EARN;

export type TOperatorEventsList = Record<TOperatorEventsType, boolean>;

export interface IOperatorEventsParams {
  type: TOperatorEventsType;
  active: boolean;
}

export interface SuperAdminUserCreateData {
  login: string;
  accounts: number[];
  password?: string;
  role: string;
}

export interface SuperAdminUser extends SuperAdminUserCreateData {
  id: number;
  operatorUserId: string;
  operators?: number[];
}

export interface ValidationRule {
  (v: any): boolean | string;
}

export interface ValidationRules {
  [key: string]: ValidationRule[];
}

export enum EPromoAccrualCondition {
  GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL',
  LESS_THAN_OR_EQUAL_TO = 'LESS_THAN_OR_EQUAL_TO',
  INTERVAL_FROM_AND_TO = 'INTERVAL_FROM_AND_TO'
}

export enum EPromoCampaignType {
  REGISTRATION_REWARD = 'REGISTRATION_REWARD',
  KYC_REWARD = 'KYC_REWARD',
  CASHBACK = 'CASHBACK',
  DEPOSIT = 'DEPOSIT',
  TOKEN_PRE_SALE = 'TOKEN_PRE_SALE',
  CUSTOM = 'CUSTOM'
}

export type TPromoCampaignType =
  | EPromoCampaignType.REGISTRATION_REWARD
  | EPromoCampaignType.KYC_REWARD
  | EPromoCampaignType.CASHBACK
  | EPromoCampaignType.DEPOSIT
  | EPromoCampaignType.TOKEN_PRE_SALE
  | EPromoCampaignType.CUSTOM;

export interface SuperAdminOperatorMenuItem {
  title: string;
  routeName: string;
  icon?: string;
  children?: SuperAdminOperatorMenuItem[];
  hide?: boolean;
}

export type TTabsItem = {
  id: string;
  text: string;
  component?: string;
  visible?: boolean;
};

export enum EStreamerStatus {
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE',
  BROADCAST = 'BROADCAST'
}

export enum EStreamerType {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC'
}

export enum EStreamingType {
  WHIP = 'WHIP',
  WEB = 'WEB'
}

export type TStreamer = {
  channelId: string;
  createdAt: string;
  followersCount: number;
  id: string;
  active: boolean;
  nickname: string;
  operatorId: number;
  playerId: number;
  playerWalletHash: string;
  status: EStreamerStatus;
  operatorUserId: string;
  type: EStreamerType;
  language: string;
  accessCode: string;
  images: {
    THREE_FOUR?: string;
    SIXTEEN_NINE?: string;
  };
  streamingType: EStreamingType;
  streamKey: string;
  streamUrl: string;
};

export type TStreamerPayload = {
  channelId: string;
  nickname: string;
  operatorId: number;
  userWalletAddress: string;
};
export type TStreamersListParams = {
  operatorId: string;
  size: number;
  page: number;
};

export interface IStreamerListResponse extends PagebleListResponse {
  content: TStreamer[];
}

export interface IDataSearchParamsRequest {
  startDate?: string;
  endDate?: string;
}

export interface ITransferSummaryDataRequest extends IDataSearchParamsRequest {}

export enum EActionType {
  BET = 'BET',
  WIN = 'WIN',
  BONUS_BET = 'BONUS_BET',
  BONUS_WIN = 'BONUS_WIN',
  ROLLBACK = 'ROLLBACK',
  ROLLBACK_BET = 'ROLLBACK_BET',
  ROLLBACK_WIN = 'ROLLBACK_WIN',
  REFUND = 'REFUND',

  // Streamer types
  BET_COPIED = 'BET_COPIED',
  WIN_COPIED = 'WIN_COPIED',
  ROLLBACK_BET_COPIED = 'ROLLBACK_BET_COPIED',
  ROLLBACK_WIN_COPIED = 'ROLLBACK_WIN_COPIED'
}

export const ActionTypeNaming = {
  [EActionType.BET]: 'BET',
  [EActionType.WIN]: 'WIN',
  [EActionType.BONUS_BET]: 'Bonus BET',
  [EActionType.BONUS_WIN]: 'Bonus WIN',
  [EActionType.ROLLBACK]: 'Rollback',
  [EActionType.ROLLBACK_BET]: 'Rollback BET',
  [EActionType.ROLLBACK_WIN]: 'Rollback WIN',
  [EActionType.REFUND]: 'Refund',

  // Streamer
  [EActionType.BET_COPIED]: 'BET copied',
  [EActionType.WIN_COPIED]: 'WIN copied',
  [EActionType.ROLLBACK_BET_COPIED]: 'Rollback BET copied',
  [EActionType.ROLLBACK_WIN_COPIED]: 'Rollback WIN copied'
};

export enum EGameType {
  CASINO = 'CASINO',
  SPORTSBOOK = 'SPORTSBOOK'
}

export const GameTypeNaming = {
  [EGameType.CASINO]: 'Casino',
  [EGameType.SPORTSBOOK]: 'Sport'
};

export type TUserGameActionDataItem = {
  amountUsd: number;
  count: number;
  actionType: EActionType;
  gameType: EGameType;
  sportBookGgr?: number;
};

export type TUserGameActionResponse = {
  casinoGgr: number;
  sportsBookGgr: number;
  gameAction: TUserGameActionDataItem[];
};

export type TStreamConfigurationResponse = {
  maxBetVolume: number;
  defaultBetVolume: number;
  maxBetVolumeActive: boolean;
  showOnlyStreamers: boolean;
  credentialsKey: string;
  credentialsSecret: string;
  streamUrl: string;
};

export type TStreamCredentials = {
  secret: string;
  key: string;
};
export enum EStreamerTransactionType {
  BET_COPIED = 'BET_COPIED',
  WIN_COPIED = 'WIN_COPIED',
  ROLLBACK_BET_COPIED = 'ROLLBACK_BET_COPIED',
  ROLLBACK_WIN_COPIED = 'ROLLBACK_WIN_COPIED'
}

export type TStreamerStat = {
  copiedActionType: EStreamerTransactionType;
  copiedActionCount: number;
  copiedActionAmountUsd: number;
};

export type TStreamerStats = {
  ggr: number;
  uniqueStreamersCount: number;
  data: TStreamerStat[];
};

export interface IStreamerDatePayload {
  from: string;
  to: string;
}

export interface IStreamerStatsBasePayload extends IStreamerDatePayload {
  operatorUserId?: string;
  userWallet?: string;
}

export type TStreamerStatsPayload = IStreamerStatsBasePayload;

export interface IStreamTransactionsBasePayload
  extends IStreamerStatsBasePayload {
  size: number;
  copiedFromWalletHash?: string;
  type?: string;
  operatorId: string | number;
}

export type TStreamTransactionsPayload = IStreamTransactionsBasePayload;

export type TStreamTransactionsByGameProviderPayload =
  TStreamTransactionsPayload & {
    provider: string;
    gameName: string;
  };

export type TStreamTransaction = {
  date: string;
  sortId: string;
  id: string;
  createdAt: string;
  userWalletHash: string;
  operatorId: string;
  operatorName: string;
  type: string;
  attributes: {
    [key: string]: string;
  };
};

export type TStreamTransactionsResponse = {
  transactions: TStreamTransaction[];
  hasNext: boolean;
};

export enum ECopyStakePackageProgram {
  MINI_PROVISION = 'mini-provision',
  STANDARD_PROVISION = 'standard-provision',
  ENTERPRISE = 'enterprise'
}

export type TCopyStakePackageResponse = {
  streamersLimit: number;
  watchersLimit: number;
};

export interface ISuperAdminOperator {
  brandName: string;
  id: number;
}

export interface IGenerateInvoiceParams {
  type: EOperatorInvoiceType;
  operatorId: number;
  fromDate: string;
  toDate: string;
  amountUsdt?: number;
  invoiceNumber: number;
}

export type GameProviderAccount = {
  id: number;
  email: string;
  gameProviderName: string;
  apiKey: string;
  state: string;
  companyName: string | null;
  companyAddress: string | null;
  createdAt: string;
  updatedAt: string;
};

export interface ICopyStakeReportPdfParams {
  date: string;
  provider: string;
}
export interface ICopyStakeReportParams extends PagebleListSearchParams {
  date: string;
}
export interface ICopyStakeReportItemResponse {
  ggr: string;
  provider: string;
}
export interface ICopyStakeReportResponse extends PagebleListResponse {
  content: ICopyStakeReportItemResponse[];
}

export interface ICopyStakeStatsStreamerParams
  extends PagebleListSearchParams,
    DateRangeParams {}

export interface ICopyStakeStatsStreamer {
  walletHash: string;
  nickname: string;
  uniqueUserCount: number;
  ftdUserCount: number;
}

export interface ICopyStakeStatsStreamersResponse extends PagebleListResponse {
  content: ICopyStakeStatsStreamer[];
}

export interface IInvitedUsers {
  id: number;
  login: string;
  expired: string;
  role: string;
}

export interface ILiquidityTransactionsRequestParams
  extends PagebleListSearchParams {
  sort: string;
  order: string;
}

export type TLiquidityTransactionItem = {
  id: number;
  operatorId: number;
  amountUsdt: number;
  type: string;
  hash: string;
  createdAt: string;
};

export type TAutoSellTokenBotSettings = {
  walletThreshold: number;
  walletTopUpAmount: number;
};

export interface TAutoSellTokenBotSettingsRequest
  extends TAutoSellTokenBotSettings {}

export type TAutoSellTokenBotInfoData = {
  id: number;
  operatorId: number;
  enable: boolean;
  workingDays: string[];
  startTime: string;
  endTime: string;
  percentageOfMovement: string;
  loverPriceLimit: string;
  minPriceImpact: string;
  maxPriceImpact: string;
  dailyLimit: string;
};

export interface IAutoSellTokenBotTransactionsCsvRequestParams {
  from: string;
  to: string;
}

export interface IAutoSellTokenBotTransactionsRequestParams
  extends IAutoSellTokenBotTransactionsCsvRequestParams {
  size: number;
}

export type TAutoSellTokenBotTransactionItem = {
  amount: number;
  wallet: string;
  date: string;
  hash: string;
  startPrice: number | null;
  salesPrice: number | null;
};

export interface IAutoSellTokenBotTransactionsListResponse {
  content: TAutoSellTokenBotTransactionItem[];
  last: boolean;
}

export enum EScheduleStreamStatus {
  PLANNED = 'PLANNED',
  FINISHED = 'FINISHED'
}

export interface IStreamingScheduleListParams extends PageData {
  sort?: string;
  order?: string;
  includeOldRecords?: boolean;
}

export type TScheduleStreamResponse = {
  id: number;
  streamerId: number | string;
  dateOfStream: string;
  images: {
    THREE_FOUR?: string;
    SIXTEEN_NINE?: string;
  };
};

export type TScheduleStream = {
  id: number;
  streamerId: number | string;
  dateOfStream: string;
  nickName: string;
  images: {
    THREE_FOUR?: string;
    SIXTEEN_NINE?: string;
  };
  createdAt: string;
  status: EScheduleStreamStatus;
};

export interface IStreamingScheduleListResponse extends PagebleListResponse {
  content: TScheduleStream[];
}

export interface ICopyStakeUsersBlacklistResponse extends PagebleListResponse {
  content: ICopyStakeUsersBlacklistItem[];
}
export interface ICopyStakeUsersBlacklistItem {
  walletHash: string;
  walletId: number;
  createdAt: string;
  operatorUserId: string;
}

export interface ICopyStakeUsersBlacklistParam extends PagebleListSearchParams {
  operatorUserId?: string;
  walletHash?: string;
}

export type TCopyStakeGameType = {
  copyStakePermitted: boolean;
  id: number;
  type: string;
};

export interface ICopyStakeGameItemResponse {
  gameTypePermitted: boolean;
  gamePermitted: boolean;
  whitelistedAt: string;
  code: string;
  provider: string;
  name: string;
  id: number;
  type: string;
  image: string;
}

export interface ICopyStakeGamesResponse extends PagebleListResponse {
  content: ICopyStakeGameItemResponse[];
}

export interface ICopyStakeGamesParams extends PagebleListSearchParams {
  permittedStates: boolean;
  gameName?: string;
  gameType?: string;
  gameCode?: string;
  providers: string[];
}

export interface ICopyStakeGamesRemoveAllParams {
  gameName?: string;
  gameType?: string;
  gameCode?: string;
  providers?: string[];
}

export interface IMenuFilterItem {
  text: string;
  value: string | number;
  active?: boolean;
}

export enum EGameSearchBy {
  NAME = 'gameName',
  CODE = 'gameCode'
}

export interface IPromoGameBlackListItem {
  name: string;
  id: number;
  provider: string;
  excluded: boolean;
  code: string;
  publisher: string;
}
export interface IPromoGameBlackListResponse extends PagebleListResponse {
  content: IPromoGameBlackListItem[];
}

export interface IPromoGameBlackListParams extends PagebleListSearchParams {
  code?: string;
  name?: string;
  providers?: string[];
}

export interface SuperAdminAccountsPayload extends PageData {
  'sort-direction': string;
  'sort-property': string;
  query?: string;
}

export type TOperatorAccount = {
  id: number;
  name: string;
  status: TSuperAdminAccountsStatus;
  createdAt: string;
};

export type TAccountWithOperators = {
  id: number;
  login: string;
  createdAt: string;
  brandName: string;
  copyStakeAccount: boolean;
  status: TSuperAdminAccountsStatus;
  operators: TOperatorAccount[];
};

export interface SuperAdminAccountsResponse extends PagebleListResponse {
  content: TAccountWithOperators[];
}

export type TCopystakeIntegration = {
  apiKey: string;
  ttl: number | string;
  callbackUrl: string;
};

export enum EProfitActivity {
  P2E = 'P2E',
  H2E = 'H2E'
}
export interface IUserProfitActivityParams {
  p2eActive: boolean;
  h2eActive: boolean;
}

export enum EMissionSegment {
  ALL = 'ALL',
  WITHOUT_BET = 'WITHOUT_BET',
  WITHOUT_H2E = 'WITHOUT_H2E',
  NEW = 'NEW',
  CUSTOM = 'CUSTOM'
}

export enum EMissionDevice {
  DESKTOP = 'DESKTOP',
  MOBILE = 'MOBILE',
  TABLE = 'TABLE'
}

export enum EMissionCountableType {
  COUNT = 'COUNT',
  AMOUNT = 'AMOUNT'
}

export enum EMissionBetConditionType {
  DEFAULT = 'DEFAULT',
  GAME = 'GAME',
  GAME_TYPE = 'GAME_TYPE'
}

export enum EMissionConditions {
  H2E = 'H2E',
  BET = 'BET',
  BET_VOLUME = 'BET_VOLUME',
  DEPOSIT = 'DEPOSIT',
  WITHDRAW = 'WITHDRAW'
}

export enum EMissionRewardType {
  TOKEN_AMOUNT = 'TOKEN_AMOUNT',
  H2E_COEFFICIENT = 'H2E_COEFFICIENT',
  EXTRA_H2E_VOLUMES = 'EXTRA_H2E_VOLUMES',
  P2E_WAGER_MULTIPLIER = 'P2E_WAGER_MULTIPLIER'
}
export enum EMissionStatus {
  ACTIVE = 'ACTIVE',
  DRAFT = 'DRAFT',
  DELAYED = 'DELAYED',
  DEACTIVATED = 'DEACTIVATED',
  DELETED = 'DELETED'
}

export interface IMissionParams {
  name: string;
  segment: EMissionSegment;
  location: string[];
  device: EMissionDevice[];
  condition: EMissionConditions;
  betConditionType: EMissionBetConditionType;
  conditionAmount: number;
  countable: boolean;
  noAccumulation: boolean;
  oncePerDayCount: boolean;
  rewardAmount: number;
  rewardType: EMissionRewardType;
  startDate: string;
  dueDate: string;
}

export interface IMissionListParams extends PageData {
  status?: EMissionStatus[];
  sort?: string;
  direction?: string;
}

export interface IMissionItem {
  id: number;
  operatorId: number;
  name: string;
  segment: EMissionSegment;
  status: EMissionStatus;
  location: string[];
  device: EMissionDevice[];
  condition: EMissionConditions;
  conditionAmount: number;
  countable: boolean;
  noAccumulation: boolean;
  oncePerDayCount: boolean;
  tokenAmount: number;
  h2eCoefficient: number;
  extraH2eVolumes: number;
  p2eWagerMultiplier: number;
  startDate: string;
  dueDate: string;
  createdAt: string;
  updatedAt: string;
  activeUsers: number;
  totalUsers: number;
}
export interface IMissionListResponse extends PagebleListResponse {
  content: IMissionItem[];
}

export interface IMissionGameType {
  included: boolean;
  id: number;
  type: string;
}

export interface IMissionGameListParams extends PageData {
  missionId?: number;
  name?: string;
  code?: string;
  providers?: [];
}

export interface IMissionGame {
  publisher: string;
  code: string;
  included: boolean;
  provider: string;
  name: string;
  id: number;
}

export interface IMissionGameListResponse extends PagebleListResponse {
  content: IMissionGame[];
}

export type TSubscribeStatus = {
  subscriptionStatus: boolean;
};

export type ICopyStakeStats = {
  online: number;
  limit: number;
};
export interface ICopyStakeStatsResponse {
  streamers: ICopyStakeStats;
  watchers: ICopyStakeStats;
}

export enum ECopyStakeDashboardChartType {
  DAU = 'DAU',
  BET_VOLUME = 'BET_VOLUME',
  GGR = 'GGR'
}

export interface ICopyStakeChartDataParam extends DateRangeParams {
  type: ECopyStakeDashboardChartType;
}

export interface ICopyStakeDashboardChartDataItem {
  date: string;
  value: number;
}

export type TCopyStakeDashboardChartDataResponse =
  ICopyStakeDashboardChartDataItem[];

export interface ICopyStakeDashboardStatsDataResponse {
  activeUsers: number;
  betVolume: number;
}

export interface ICopyStakeDashboardViewersDataResponse {
  viewers: number;
  uniqueViewers: number;
  viewersWithBet: number;
}
